<script setup lang="ts">
    import type DataObject from 'o365.modules.DataObject.ts';
    import 'o365.modules.DataObject.extensions.PagedData.ts';
    import Paginator from 'o365.vue.components.Paginator.vue';

    /*
        SLOTS
        - default for setting up what each row of the list should contain
        - heading for setting the list heading
        - actions for setting the action/button section to the right of heading
    */

    const props = defineProps<{
        dataObject: DataObject,
        /* Classes string for the list container */
        listClass?: string,
        /* Don't show the header at all */
        disableHeader?: boolean,
        /* Move the pagination controls beside the title (can be overwritten by actions) */
        showPaginationInHeader?: boolean,
        /* Hide the border that appears under header text */
        hideHeaderBorder?: boolean,
        /* Disable dropdown that lets the user adjust list page size */
        disablePageSizeDropdown?: boolean,
        /** Default page size */
        pageSize?: number
    }>();

    props.dataObject.pagedData.enable({
        initialPageSize: props.pageSize
    });
    // props.dataObject.load();
</script>

<template>
    <h5 v-if="!disableHeader" class="d-flex flex-wrap justify-content-between text-muted  gap-2 mb-0" :class="{'border-bottom':!hideHeaderBorder}" style="min-height:32px;">
        <slot name="heading"></slot>

        <slot name="actions">
            <template v-if="showPaginationInHeader">
                <span class="fw-normal" style="font-size:initial;">
                    <Paginator :dataObject="dataObject" :pageSizeDropdown="disablePageSizeDropdown ? null : true"></Paginator>
                </span>
            </template>
        </slot>
    </h5>
    
    <div class="list-unstyled w-100 mb-0" :class="listClass">
        <div v-for="row in dataObject.data">
            <slot :row="row"></slot>
        </div>
    </div>

    <template v-if="!showPaginationInHeader">
        <Paginator :dataObject="dataObject" :pageSizeDropdown="disablePageSizeDropdown ? null : true"></Paginator>
    </template>
</template>